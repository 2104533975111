.footer-main {
    padding: 50px 0 45px;
    background-color: #fff;
    border-top: 1px solid #dbdbdb;
    &-box {
        display: flex;
        align-items: flex-start;
    }
    @media screen and (max-width: 1660px) {
        font-size: 15px;
    }
    @media screen and (max-width: 1440px) {
        padding: 40px 0 35px;
        font-size: 14px;
    }
    @media screen and (max-width: 1200px) {
        &-box {
            display: block;
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(60px) 0 px2rem(55px);
        font-size: 13px;
    }
}

.footer-link {
    display: flex;
    margin-bottom: 55px;
    padding-bottom: 18px;
    border-bottom: 1px solid #ececec;
    &-title {
        color: #333;
    }
    &-list {
        flex: 1;
        a {
            display: inline-block;
            vertical-align: top;
            margin: 0 32px 8px 0;
            color: #999;
            transition: all .3s;
            &:hover {
                color: $blueColor;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        &-list {
            a {
                margin-right: 25px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(50px);
        padding-bottom: px2rem(25px);
        a {
            margin: 0 px2rem(25px) px2rem(25px) 0;
        }
    }
}

.footer-nav {
    flex: 1;
    display: flex;
    dl {
        flex: 1;
        padding-right: 10px;
    }
    dt {
        font-weight: bold;
        a {
            color: #333;
            transition: all .3s;
            &:hover {
                color: $blueColor;
            }
        }
    }
    dd {
        margin-top: 18px;
        a {
            color: #999;
            transition: all .3s;
            &:hover {
                color: $blueColor;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        dl {
            flex: auto;
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
        dl {
            margin-bottom: px2rem(12px);
            padding-right: 0;
        }
        dt {
            padding-bottom: px2rem(35px);
        }
        dd {
            display: inline-block;
            vertical-align: top;
            margin: 0 px2rem(25px) px2rem(25px) 0;
        }
    }
}

.footer-right {
    display: flex;
    width: 800px;
    padding-left: (100/1725) * 100%;
    border-left: 1px solid #ececec;
    box-sizing: border-box;
    .p {
        flex: 1;
        margin-top: -5px;
        line-height: 2;
        color: #333;
    }
    .qrcode-img {
        margin-left: 40px;
        width: 192px;
        img {
            width: 100%;
            height: auto;
        }
    }
    @media screen and (max-width: 1660px) {
        width: 660px;
        .qrcode-img {
            margin-left: 30px;
            width: 166px;
        }
    }
    @media screen and (max-width: 1440px) {
        padding-left: 3%;
        width: 590px;
        .qrcode-img {
            margin-left: 20px;
            width: 140px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-top: 30px;
        padding: 30px 0 0;
        width: 100%;
        border-left: none;
        border-top: 1px solid #dbdbdb;
    }
    @media screen and (max-width: 750px) {
        display: block;
        margin-top: px2rem(25px);
        padding-top: px2rem(40px);
        .p {
            margin-top: 0;
            font-size: 12px;
        }
        .qrcode-img {
            margin: px2rem(35px) 0 0;
            width: 100%;
            text-align: center;
            img {
                display: inline-block;
                max-width: 40%;
                width: auto;
            }
        }
    }
}

.footer {
    padding: 20px 0;
    background-color: #454545;
    .pd100 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-p {
        line-height: 1.6;
        color: #fff;
        a {
            display: inline-block;
            vertical-align: top;
            margin-left: 16px;
            color: #fff;
            transition: all .3s;
            &:hover {
                opacity: .8;
            }
        }
    }
    .bdsharebuttonbox {
        a {
            float: none;
            display: inline-block;
            padding: 0;
            margin: 0;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 17px;
            color: #fff;
            border-radius: 50%;
            background: rgba(255, 255, 255, .2);
            &:hover {
                color: #fff;
                background: rgba(255, 255, 255, .3);
                opacity: 1;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        font-size: 15px;
    }
    @media screen and (max-width: 1440px) {
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(45px) 0;
        font-size: 13px;
        text-align: center;
        .pd100 {
            display: block;
        }
        &-p {
            line-height: 1.8;
        }
        .bdsharebuttonbox {
            margin-top: px2rem(18px);
            a {
                width: 35px;
                height: 35px;
                line-height: 35px;
                font-size: 15px;
            }
        }
    }
}

.back-top {
    position: fixed;
    bottom: 20%;
    right: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 78px;
    height: 78px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    transition: all .3s;
    z-index: 2000;
    &.on {
        display: flex;
    }
    .icon {
        font-size: 24px;
        color: #ababab;
    }
    p {
        color: #999;
    }
    &:hover {
        background-color: $blueColor;
        p,
        .icon {
            color: #fff;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 1660px) {
        width: 68px;
        height: 68px;
        font-size: 14px;
        .icon {
            font-size: 20px;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 58px;
        height: 58px;
        font-size: 12px;
        .icon {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(5px);
        width: px2rem(60px);
        height: px2rem(60px);
        .icon {
            font-size: px2rem(30px);
        }
        p {
            display: none;
        }
    }
}
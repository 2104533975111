.index-banner {
    position: relative;
    width: 100%;
    padding-bottom: (820/1920) * 100%;
    &-box {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .img-pc,
        .img-mobile {
            width: 100%;
            height: 100%;
            background: #fff none no-repeat center center;
            background-size: cover;
        }
        .img-mobile {
            display: none;
        }
        &.no-init ~ .banner-prev,
        &.no-init ~ .banner-next {
            display: none;
        }
    }
    .banner-prev,
    .banner-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 64px;
        color: #fff;
        outline: none;
        z-index: 999;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            color: $blueColor;
        }
    }
    .banner-prev {
        left: 80px;
    }
    .banner-next {
        right: 80px;
        transform: translateY(-50%) rotate(180deg);
    }
    .banner-pagination {   
        position: absolute;
        left: 0;
        bottom: 36px;
        width: 100%;
        text-align: center;
        z-index: 999;
        .swiper-pagination-bullet {
            margin: 0 px2rem(8px);
            width: 14px;
            height: 14px;
            background: #fff;
            border-radius: 14px;
            outline: none;
            opacity: 1;
            transition: all .3s;
            &.swiper-pagination-bullet-active {
                width: 35px;
                background-color: $blueColor
            }
        }
    }
    @media screen and (max-width: 1660px) {
        .banner-prev,
        .banner-next {
            font-size: 50px;
        }
        .banner-prev {
            left: 55px;
        }
        .banner-next {
            right: 55px;
        }
        .banner-pagination {   
            bottom: 24px;
            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                border-radius: 12px;
                &.swiper-pagination-bullet-active {
                    width: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .banner-prev,
        .banner-next {
            font-size: 45px;
        }
        .banner-prev {
            left: px2rem(30px);
        }
        .banner-next {
            right: px2rem(30px);
        }
    }
    @media screen and (max-width: 750px) {
        padding-bottom: (980/750) * 100%;
        &-box {
            .img-pc {
                display: none;
            }
            .img-mobile {
                display: block;
            }
        }
        .banner-prev,
        .banner-next {
            font-size: px2rem(44px);
        }
        .banner-pagination {   
            bottom: px2rem(40px);
            .swiper-pagination-bullet {
                width: px2rem(18px);
                height: px2rem(18px);
                border-radius: px2rem(18px);
                &.swiper-pagination-bullet-active {
                    width: px2rem(50px);
                }
            }
        }
    }
}

.index-page {
    background-color: #f5f5f5;
    overflow: hidden;
}

.index-about {
    padding: 60px 0 100px;
    &-box {
        display: flex;
        box-shadow: 0 40px 100px -75px rgba(0, 0, 0, .8);
        &:hover {
            .info-title {
                .title {
                    color: $blueColor;
                }
            }
            .img div {
                transform: scale(1.05);
            }
        }
        .info {
            padding: 60px 165px 50px 70px;
            width: 695px;
            background-color: #fff;
            box-sizing: border-box;
            &-title {
                margin-bottom: 40px;
                .title {
                    font-size: 40px;
                    color: #333;
                    transition: all .3s;
                }
                p {
                    font-family: 'Cera-GR-Bold';
                    text-transform: uppercase;
                    color: #999;
                }
            }
            &-content {
                height: 340px;
                line-height: 34px;
                color: #888;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 10;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
            &-data {
                display: flex;
                margin-top: 18px;
                padding-top: 26px;
                text-align: center;
                border-top: 1px dashed #e0e0e0;
                li {
                    flex: 1;
                    &:not(:first-child) {
                        margin-left: 60px;
                    }
                    .num {
                        font-family: 'Cera-GR-Bold';
                        font-size: 35px;
                        color: #333;
                        span {
                            font-size: 20px;
                        }
                    }
                    p {
                        margin-top: 8px;
                        line-height: 24px;
                        color: #999;
                    }
                }
            }
        }
        .right {
            flex: 1;
            margin-left: -95px;
        }
        .img {
            margin-top: 60px;
            height: calc(100% - 60px);
            overflow: hidden;
            div {
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transition: all .5s;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        &-box {
            .info {
                padding: 50px 140px 50px 60px;
                width: 600px;
                &-title {
                    margin-bottom: 30px;
                    .title {
                        font-size: 36px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
                &-content {
                    height: 300px;
                    line-height: 30px;
                    font-size: 15px;
                }
                &-data {
                    li {
                        &:not(:first-child) {
                            margin-left: 40px;
                        }
                        .num {
                            font-size: 30px;
                            span {
                                font-size: 14px;
                            }
                        }
                        p {
                            font-size: 15px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        padding-bottom: px2rem(80px);
        &-box {
            .info {
                padding: 40px 100px 40px 50px;
                width: 520px;
                &-title {
                    margin-bottom: 20px;
                    .title {
                        font-size: 30px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
                &-content {
                    height: 280px;
                    line-height: 28px;
                    font-size: 14px;
                }
                &-data {
                    padding-top: 20px;
                    li {
                        p {
                            margin-top: 5px;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
            .right {
                margin-left: -60px;
            }
            .img {
                margin-top: 50px;
                height: calc(100% - 50px);
            }
        }
    }
    @media screen and (max-width: 950px) {
        padding-top: px2rem(70px);
        &-box {
            display: block;
            box-shadow: 0 px2rem(40px) px2rem(80px) px2rem(-40px) rgba(0, 0, 0, .8);
            .info {
                padding: px2rem(50px) px2rem(30px);
                width: 100%;
                &-content {
                    display: block;
                    height: auto;
                }
                &-data {
                    li {
                        &:not(:first-child) {
                            margin-left: 150px;
                        }
                    }
                }
            }
            .right {
                margin-left: 0;
            }
            .img {
                position: relative;
                margin-top: 0;
                height: 0;
                padding-bottom: (653/962) * 100%;
                div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-box {
            .info {
                &-title {
                    margin-bottom: 10px;
                    .title {
                        font-size: 22px;
                    }
                }
                &-data {
                    margin-top: 15px;
                    padding-top: 16px;
                    li {
                        &:not(:first-child) {
                            margin-left: px2rem(40px);
                        }
                        .num {
                            font-size: 24px;
                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.index-title {
    margin-bottom: 50px;
    text-align: center;
    a {
        position: relative;
        display: block;
        padding: 30px 0 55px;
        overflow: hidden;
        &:hover {
            .h1 {
                color: $blueColor;
            }
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            margin-left: -31px;
            width: 62px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:before {
            top: 0;
            height: 33px;
            background-image: url(../images/menu_bg2.png);
        }
        &:after {
            bottom: 0;
            height: 51px;
            background-image: url(../images/menu_bg3.png);
        }
    }
    .h1 {
        font-size: 35px;
        color: #000;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
        transition: all .3s;
    }
    p {
        font-family: 'Cera-GR-Bold'; 
        font-size: 14px;
        color: #999;
        letter-spacing: 10px;
        text-transform: uppercase;
        text-shadow: .5px .8px 1px rgba(0, 0, 0, .2);
    }
    @media screen and (max-width: 1660px) {
        a {
            padding: 28px 0 50px;
            &:before,
            &:after {
                margin-left: -28px;
                width: 56px;
            }
            &:before {
                height: 30px;
            }
            &:after {
                height: 46px;
            }
        }
        .h1 {
            font-size: 30px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 950px) {
        margin-bottom: 30px;
        a {
            padding: 22px 0 38px;
            &:before,
            &:after {
                margin-left: -23px;
                width: 46px;
            }
            &:before {
                height: 24px;
            }
            &:after {
                height: 38px;
            }
        }
        .h1 {
            font-size: 26px;
        }
        p {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(50px);
        a {
            padding: 18px 0 32px;
            &:before,
            &:after {
                margin-left: -18px;
                width: 36px;
            }
            &:before {
                height: 19px;
            }
            &:after {
                height: 30px;
            }
        }
        .h1 {
            font-size: 22px;
        }
        p {
            font-size: 10px;
            letter-spacing: 6px;
        }
    }
}

.index-service-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    li {
        width: 25%;
        a {
            position: relative;
            display: block;
            height: 0;
            padding-bottom: (480/431) * 100%;
            overflow: hidden;
            &:hover {
                .a-title {
                    transform: translateY(100%);
                }
                .a-mask {
                    bottom: 0;
                }
            }
        }
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .a-title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 35px 35px 24px;
            color: #f5f5f5;
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .5) 40%, rgba(0, 0, 0, .8));
            box-sizing: border-box;
            z-index: 2;
            transform: translateY(0);
            transition: all .3s .2s;
            .h1 {
                font-size: 30px;
            }
            p {
                margin-top: 3px;
                text-transform: uppercase;
            }
            .h1,
            p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .a-mask {
            position: absolute;
            bottom: 100%;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0 px2rem(20px);
            width: 100%;
            height: 100%;
            color: #f5f5f5;
            background: rgba(0, 131, 214, .9);
            overflow: hidden;
            box-sizing: border-box;
            z-index: 8;
            transition: all .5s;
            .icon {
                margin: 0 auto 55px;
                width: 112px;
                height: 84px;
                line-height: 84px;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .title {
                font-size: 30px;
            }
            p {
                margin-top: 5px;
                text-transform: uppercase;
            }
            .title,
            p {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    @media screen and (max-width: 1800px) {
        li {
            .a-title {
                padding: 35px 30px 24px;
                .h1 {
                    font-size: 30px;
                }
                p {
                    font-size: 14px;
                }
            }
            .a-mask {
                p {
                    margin-top: 3px;
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 1660px) {
        li {
            .a-title {
                padding: 30px 30px 20px;
                .h1 {
                    font-size: 26px;
                }
                p {
                    font-size: 13px;
                }
            }
            .a-mask {
                .icon {
                    margin: 0 auto 40px;
                    width: 100px;
                    height: 75px;
                    line-height: 75px;
                }
                .title {
                    font-size: 26px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 50px;
        li {
            .a-title {
                padding: 30px 20px 15px;
                .h1 {
                    font-size: 22px;
                }
                p {
                    font-size: 12px;
                }
            }
            .a-mask {
                .icon {
                    margin-bottom: 30px;
                    width: 80px;
                    height: 60px;
                    line-height: 60px;
                }
                .title {
                    font-size: 22px;
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            .a-title {
                padding: 30px 15px 12px;
                .h1 {
                    font-size: 20px;
                }
                p {
                    font-size: 10px;
                }
            }
            .a-mask {
                .icon {
                    margin-bottom: 25px;
                    width: 68px;
                    height: 51px;
                    line-height: 51px;
                }
                .title {
                    font-size: 20px;
                }
                p {
                    font-size: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        margin-bottom: 40px;
        li {
            width: 50%;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(50px);
        li {
            .a-title {
                padding: 20px 15px 10px;
                .h1 {
                    font-size: 16px;
                }
                p {
                    font-size: 8px;
                }
            }
            .a-mask {
                .icon {
                    margin-bottom: 15px;
                    width: 56px;
                    height: 42px;
                    line-height: 42px;
                }
                .title {
                    font-size: 16px;
                }
                p {
                    font-size: 8px;
                }
            }
        }
    }
}


.index-more {
    a {
        display: block;
        margin: 0 auto;
        width: 343px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-weight: 600;
        color: #fff;
        background-color: #454545;
        transition: all .3s;
        &:hover {
            background-color: $blueColor;
        }
    }
    @media screen and (max-width: 1200px) {
        a {
            width: 320px;
            height: 40px;
            line-height: 40px;
            font-size: 15px;
        }
    }
    @media screen and (max-width: 750px) {
        a {
            width: 100%;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
        }
    }
}

.index-industry {
    padding: 95px 0;
    &-box {
        position: relative;
        margin-top: px2rem(-8px);
    }
    .industry-prev,
    .industry-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #666;
        border: 1px solid #666;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        transition: all .3s;
        &:before {
            display: inline-block;
            vertical-align: middle;
            height: 21px;
            line-height: 21px;
        }
        &:hover {
            color: #fff;
            background-color: $blueColor;
            border-color: $blueColor;
        }
    }
    .industry-prev {
        left: -66px;
    }
    .industry-next {
        right: -66px;
    }
    .industry-pagination {
        display: none;
        margin-bottom: 40px;
        text-align: center;
        .swiper-pagination-bullet {
            margin: 0 5px;
            width: 10px;
            height: 10px;
            opacity: 1;
            background-color: #666;
            border-radius: 50%;
            &-active {
                background-color: $blueColor;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        .industry-prev,
        .industry-next {
            width: 38px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            &:before {
                height: 18px;
                line-height: 18px;
            }
        }
        .industry-prev {
            left: -50px;
        }
        .industry-next {
            right: -50px;
        }
    }
    @media screen and (max-width: 1440px) {
        padding: px2rem(80px) 0;
        .industry-prev,
        .industry-next {
            width: 32px;
            height: 32px;
            line-height: 35px;
            font-size: 13px;
            &:before {
                height: 16px;
                line-height: 16px;
            }
        }
        .industry-prev {
            left: -48px;
        }
        .industry-next {
            right: -48px;
        }
    }
    @media screen and (max-width: 1200px) {
        .industry-prev,
        .industry-next {
            display: none;
        }
        .industry-pagination {
            display: block;
        }
    }    
    @media screen and (max-width: 750px) {
        .industry-pagination {
            margin-bottom: px2rem(40px);
        }
    }
}

.industry-swiper {
    padding: px2rem(8px) 0 60px;
    overflow: hidden;
    .swiper-slide {
        width: 25%;
        box-sizing: border-box;
        a {
            display: block;
            background-color: #fff;
            box-shadow: 0 20px 50px -20px rgba(0, 0, 0, .4);
            cursor: pointer;
            transition: all .3s;
            &:hover {
                transform: translateY(px2rem(-8px));
                .img {
                    &:before {
                        top: 0;
                    }
                    &:after {
                        top: 0;
                    }
                }
                h3,
                .more,
                .more i {
                    color: $blueColor;
                }
            }
        }
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (320/394) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 131, 214, .8);
                z-index: 6;
                transition: all .4s;
            }
            &:after {
                content: '\e659';
                position: absolute;
                top: -100%;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding-top: 8px;
                font-family: 'iconfont';
                font-size: 60px;
                color: #fff;
                box-sizing: border-box;
                z-index: 8;
                transition: all .4s;
            }
        }
        .info {
            padding: 28px 32px 50px;
        }
        h3 {
            height: 20px*1.2;
            line-height: 1.2;
            font-size: 20px;
            color: #333;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            transition: all .3s;
        }
        .p {
            margin: 18px 0 35px;
            height: 150px;
            font-size: 16px;
            line-height: 30px;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            word-break: break-all;
        }
        .more {
            font-family: 'Cera-GR-Bold';
            font-size: 18px;
            color: #454545;
            transition: all .3s;
            i {
                margin-right: 6px;
                color: #6a6a6a;
                transition: all .3s;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        .swiper-slide {
            .img {
                &:after {
                    font-size: 46px;
                }
            }
            .info {
                padding: 25px 25px 35px;
            }
            .p {
                margin: 15px 0 20px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        padding-bottom: 50px;
        .swiper-slide  {
            .img {
                &:after {
                    font-size: 36px;
                }
            }
            .info {
                padding: 20px 20px 30px;
            }
            h3 {
                height: 18px*1.2;
                font-size: 18px;
            }
            .p {
                margin: 10px 0 15px;
                height: 28px*5;
                line-height: 28px;
                font-size: 14px;
            }
            .more {
                font-size: 16px;
                i {
                    margin-right: 6px;
                    font-size: 14px;
                }
            }
        }
    } 
    @media screen and (max-width: 1200px) {
        padding-bottom: px2rem(30px);
    }
    @media screen and (max-width: 950px) {
        padding-bottom: px2rem(30px);
        .swiper-slide {
            width: 50%;
            .slide-item:first-child {
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .swiper-slide {
            .a-box,
            a {
                box-shadow: 0 px2rem(10px) px2rem(30px) px2rem(-12px) rgba(0, 0, 0, .3);
                .img {
                    &:after {
                        font-size: 30px;
                    }
                }
            }
            .info {
                padding: px2rem(35px) px2rem(30px) px2rem(50px);
            }
            h3 {
                height: 16px*1.2;
                font-size: 16px;
            }
            .p {
                margin: 8px 0 13px;
                height: 26px*5;
                line-height: 26px;
                font-size: 13px;
            }
            .more {
                font-size: 14px;
                i {
                    margin-right: 4px;
                    font-size: 12px;
                }
            }
            .slide-item:first-child {
                margin-bottom: 8px;
            }
        }
    }
}

.index-news {
    padding-bottom: 100px;
    @media screen and (max-width: 1440px) {
        padding-bottom: px2rem(80px);
    }
    &-recommond {
        margin-bottom: 50px;
        a {
            display: flex;
            background-color: #fff;
            box-shadow: 0 px2rem(20px) px2rem(50px) px2rem(-20px) rgba(0, 0, 0, .4);
            &:hover {
                .img img {
                    transform: scale(1.05);
                }
                .info {
                    .title,
                    .more,
                    .more i {
                        color: $blueColor;
                    }
                }
            }
        }
        .img {
            position: relative;
            width: 50%;
            height: 0;
            padding-bottom: (486/863) * 50%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all .5s;
            }
        }
        .info {
            flex: 1;
            width: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 45px 0 75px;
            box-sizing: border-box;
            .time {
                margin: -38px 0 38px;
                font-size: 50px;
                font-family: 'Cera-GR-Bold';
                color: #ccc;
            }
            .title {
                height: 28px*1.2;
                line-height: 1.2;
                font-size: 28px;
                color: #000;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all .3s;
            }
            .p {
                margin: 16px 0 45px;
                max-height: 36px*3;
                line-height: 36px;
                color: #666;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
            .more {
                font-family: 'Cera-GR-Bold';
                font-size: 18px;
                color: #454545;
                transition: all .3s;
                i {
                    margin-right: 6px;
                    color: #6a6a6a;
                    transition: all .3s;
                }
            }
        }
        @media screen and (max-width: 1660px) {
            .info {
                padding: 0 40px 0 55px;
                .time {
                    margin: -10px 0 35px;
                    font-size: 44px;
                }
                .title {
                    height: 24px*1.2;
                    font-size: 24px;
                }
                .p {
                    margin: 16px 0 30px;
                    max-height: 32px*3;
                    line-height: 32px;
                    font-size: 15px;
                }
            }
        }
        @media screen and (max-width: 1440px) {
            .info {
                padding: 0 35px;
                .time {
                    margin: -10px 0 25px;
                    font-size: 40px;
                }
                .title {
                    height: 22px*1.2;
                    font-size: 22px;
                }
                .p {
                    margin: 14px 0 30px;
                    max-height: 28px*3;
                    line-height: 28px;
                    font-size: 14px;
                }
                .more {
                    font-size: 16px;
                    i {
                        font-size: 14px;
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            margin-bottom: 40px;
            .info {
                padding: 0 35px;
                .time {
                    margin: -10px 0 18px;
                    font-size: 36px;
                }
                .title {
                    height: 20px*1.2;
                    font-size: 20px;
                }
                .p {
                    margin: 12px 0 25px;
                }
                .more {
                    font-size: 15px;
                    i {
                        font-size: 13px;
                    }
                }
            }
        }
        @media screen and (max-width: 950px) {
            margin-bottom: px2rem(30px);
            a {
                display: block;
            }
            .img {
                width: 100%;
                padding-bottom: (486/863) * 100%;
            }
            .info {
                padding: px2rem(20px) px2rem(30px) px2rem(50px);
                width: 100%;
                .time {
                    margin-top: 0;
                }
            }
        }
        @media screen and (max-width: 750px) {
            .info {
                padding-top: px2rem(30px);
                .time {
                    margin-bottom: 12px;
                    font-size: 28px;
                }
                .title {
                    height: 18px*1.2;
                    font-size: 18px;
                }
                .p {
                    margin: 10px 0 18px;
                    max-height: 26px*3;
                    font-size: 13px;
                    line-height: 26px;
                }
                .more {
                    font-size: 14px;
                    i {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    &-list {
        display: flex;
        li {
            flex: 1;
            width: 0;
            &:not(:first-child) {
                margin-left: 50px;
            }
            a {
                display: block;
                background: #fff;
                box-shadow: 0 px2rem(20px) px2rem(50px) px2rem(-20px) rgba(0, 0, 0, .4);
                &:hover {
                    .img img {
                        transform: scale(1.1);
                    }
                    .title {
                        color: $blueColor;
                    }
                }
            }
            .img {
                position: relative;
                height: 0;
                padding-bottom: (243/428) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                }
            }
            .time {
                position: absolute;
                right: 0;
                bottom: 0;
                padding-top: 4px;
                width: 70px;
                height: 70px;
                text-align: center;
                font-family: 'Cera-GR-Bold';
                color: #fff;
                background-color: $blueColor;
                box-sizing: border-box;
                z-index: 2;
                .day {
                    font-size: 28px;
                }
            }
            .info {
                padding: 42px 30px 38px;
            }
            .title {
                height: 20px*1.2;
                line-height: 1.2;
                font-size: 20px;
                color: #000;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all .3s;
            }
            .p {
                margin-top: 16px;
                height: 60px;
                line-height: 30px;
                color: #999;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
        }
        @media screen and (max-width: 1440px) {
            li {
                .time {
                    padding-top: 0;
                    width: 58px;
                    height: 58px;
                    .day {
                        font-size: 24px;
                    }
                    .year {
                        font-size: 13px;
                    }
                }
                .info {
                    padding: 26px 20px;
                }
                .title {
                    height: 18px*1.2;
                    font-size: 18px;
                }
                .p {
                    margin-top: 12px;
                    height: 56px;
                    line-height: 28px;
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            li {
                &:not(:first-child) {
                    margin-left: 40px;
                }
                .time {
                    width: 52px;
                    height: 52px;
                    .day {
                        font-size: 20px;
                    }
                    .year {
                        font-size: 12px;
                    }
                }
                .info {
                    padding: 22px 15px 20px;
                }
                .title {
                    height: 16px*1.2;
                    font-size: 16px;
                }
                .p {
                    height: 52px;
                    line-height: 26px;
                    font-size: 13px;
                }
            }
        }
        @media screen and (max-width: 950px) {
            display: block;
            li {
                width: 100%;
                &:not(:first-child) {
                    margin: px2rem(30px) 0 0;
                }
                a {
                    display: flex;
                    align-items: center;
                }
                .img {
                    width: 40%;
                    padding-bottom: (243/428) * 40%;
                }
                .info {
                    flex: 1;
                    width: 0;
                    padding: 0 px2rem(30px);
                }
            }
        }
        @media screen and (max-width: 750px) {
            li {
                .time {
                    width: 36px;
                    height: 36px;
                    .day {
                        font-size: 16px;
                    }
                    .year {
                        font-size: 8px;
                    }
                }
                .title {
                    height: auto;
                    max-height: 48px;
                    line-height: 24px;
                    font-size: 15px;
                    white-space: normal;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
                .p {
                    display: none;
                }
            }
        }
    }
}
.about-page {
    padding: 80px 0 100px;
    background-color: #f5f5f5;
    @media screen and (max-width: 1440px) {
        padding: px2rem(60px) 0 px2rem(70px);
    }
}

.about-company {
    display: flex;
    background-color: #fff;
    box-shadow: 0 40px 100px -50px rgba(0, 0, 0, .8);
    &-info {
        flex: 1;
        padding: 75px 75px 50px;
        .info-title {
            margin-bottom: 30px;
            h2 {
                font-size: 40px;
                color: #333;
            }
            p {
                font-family: 'Cera-GR-Bold';
                text-transform: uppercase;
                color: #999;
            }
        }
        .info-p {
            line-height: 36px;
            color: #333;
        }
        .info-data {
            display: flex;
            margin-top: 15px;
            padding-top: 30px;
            text-align: center;
            border-top: 1px dashed #e0e0e0;
            li {
                flex: 1;
                padding: 0 45px;
                .num {
                    font-family: 'Cera-GR-Bold';
                    font-size: 35px;
                    color: #333;
                    span {
                        font-size: 20px;
                    }
                }
                p {
                    margin-top: 8px;
                    line-height: 24px;
                    color: #999;
                }
            }
        }
    }
    &-video {
        width: (962/1725) * 100%;
        .video-box {
            position: relative;
            height: 100%;
            min-height: 34vw;
            overflow: hidden;
        }
        .mejs-container,
        .mejs-overlay,
        .mejs-poster,
        video {
            position: absolute;
            width: 100%!important;
            height: 100%!important;
            overflow: hidden
        }
        .mejs-poster {
            background-size: cover;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .5);
            }
        }
        .mejs-overlay-button {
            width: 65px;
            height: 65px;
            margin: -33px 0 0 -33px;
            background-size: 100% 100%;
        }
    }
    @media screen and (max-width: 1800px) {
        &-info {
            .info-data {
                li {
                    padding: 0 6%;
                }
            }
        }
    }
    @media screen and (max-width: 1660px) {
        &-video {
            width: 50%;   
        }
    }
    @media screen and (max-width: 1440px) {
        &-info {
            padding: 50px;
            .info-title {
                margin-bottom: 25px;
                h2 {
                    font-size: 30px;
                }
                p {
                    font-size: 14px;
                }
            }
            .info-p {
                font-size: 14px;
                line-height: 2.3;
            }
            .info-data {
                padding-top: 25px;
                li {
                    .num {
                        font-size: 30px;
                        span {
                            font-size: 14px;
                        }
                    }
                    p {
                        margin-top: 8px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        display: block;
        box-shadow: 0 px2rem(30px) px2rem(60px) px2rem(-40px) rgba(0, 0, 0, .8);
        &-info {
            .info-title {
                text-align: center;
            }
        }
        &-video {
            width: 100%;
            .video-box {
                height: 0;
                min-height: 0;
                padding-bottom: (653/962) * 100%;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-info {
            padding: px2rem(50px) px2rem(30px);
            .info-title {
                margin-bottom: 12px;
                h2 {
                    font-size: 24px;
                }
                p {
                    font-size: 12px;
                }
            }
            .info-p {
                line-height: 2;
            }
            .info-data {
                padding-top: 10px;
                li {
                    padding: 0 px2rem(15px);
                    .num {
                        font-size: 24px;
                        span {
                            font-size: 12px;
                        }
                    }
                    p {
                        margin-top: 6px;
                        font-size: 13px;
                    }
                }
            }
        }
        &-video {
            .mejs-overlay-button {
                width: 40px;
                height: 40px;
                margin: -20px 0 0 -20px;
            }
        }
    }
}


.about-history {
    position: relative;
    padding: 60px 100px;
    background-color: #fff;
    box-shadow: 0 40px 100px -50px rgba(0, 0, 0, .8);
    overflow: hidden;
    &-title {
        position: absolute;
        top: 138px;
        left: 50%;
        width: 50%;
        padding-left: 70px;
        color: rgba(51, 51, 51, .1);
        box-sizing: border-box;
        h2 {
            font-weight: 600;
            font-size: 80px;
        }
        p {
            margin-top: -6px;
            font-size: 120px;
            text-transform: uppercase;
            font-family: 'IMPACT'; 
        }
    }
    &-box {
        position: relative;
        z-index: 1;
        .top-icon {
            position: relative;
            text-align: center;
            z-index: 8;
            img {
                display: inline-block;
                max-width: 100%;
            }
        }
        .line {
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -18px;
            width: 36px;
            height: 100%;
            background: $blueColor url(../images/line.png) top -10px center repeat-y;
            border-radius: 36px;
            z-index: 2;
        }
        .bottom-icon {
            position: absolute;
            left: 50%;
            bottom: 0;
            margin-left: -28px;
            width: 56px;
            height: 56px;
            background-color: $blueColor;
            border-radius: 50%;
            z-index: 6;
            &:after {
                content: '';
                position: absolute;
                top: 14px;
                left: 14px;
                width: 28px;
                height: 28px;
                background-color: #fff;
                border-radius: 50%;
            }
        }
    }
    @media screen and (max-width: 1800px) {
        &-title {
            h2 {
                font-size: 75px;
            }
            p {
                font-size: 110px;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        padding: 60px;
        &-title {
            top: 100px;
            padding-left: 60px;
            h2 {
                font-size: 58px;
            }
            p {
                font-size: 99px;
            }
        }
        &-box {
            .top-icon {
                img {
                    width: 70px;
                }
            }
            .line {
                margin-left: -15px;
                width: 30px;
                background-size: 15px auto;
            }
            .bottom-icon {
                margin-left: -23px;
                width: 46px;
                height: 46px;
                &:after {
                    top: 13px;
                    left: 13px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 50px 40px;
        &-title {
            top: 80px;
            padding-left: 50px;
            h2 {
                font-size: 48px;
            }
            p {
                font-size: 88px;
            }
        }
        &-box {
            .top-icon {
                img {
                    width: 60px;
                }
            }
            .line {
                margin-left: -13px;
                width: 26px;
                background-size: 13px auto;
            }
            .bottom-icon {
                margin-left: -20px;
                width: 40px;
                height: 40px;
                &:after {
                    top: 12px;
                    left: 12px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 40px 30px;
        box-shadow: 0 px2rem(30px) px2rem(60px) px2rem(-40px) rgba(0, 0, 0, .8);
        &-title {
            padding-left: 40px;
            h2 {
                font-size: 38px;
            }
            p {
                font-size: 68px;
            }
        }
        &-box {
            .top-icon {
                img {
                    width: 50px;
                }
            }
            .line {
                margin-left: -11px;
                width: 22px;
                background-size: 11px auto;
            }
            .bottom-icon {
                margin-left: -18px;
                width: 36px;
                height: 36px;
                &:after {
                    top: 10px;
                    left: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        padding: px2rem(50px) px2rem(30px) px2rem(60px) px2rem(20px);
        &-title {
            top: 70px;
            left: 0;
            padding-left: 80px;
            width: 100%;
        }
        &-box {
            .top-icon {
                text-align: left;
            }
            .line {
                margin-left: 13px;
                left: 0;
            }
            .bottom-icon {
                margin-left: 6px;
                left: 0;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-title {
            top: calc(.25rem + 24px);
            padding-left: 54px;
            h2 {
                font-size: 24px;
            }
            p {
                font-size: 42px;
            }
        }
        &-box {
            margin-top: 0;
            .top-icon {
                img {
                    width: 32px;
                }
            }
            .line {
                margin-left: 8px;
                width: 16px;
                background-size: 5px auto;
            }
            .bottom-icon {
                margin-left: 4px;
                width: 26px;
                height: 26px;
                &:after {
                    top: 8px;
                    left: 8px;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}

.history-list {
    position: relative;
    margin: 75px -66px 0;
    .history-item {
        position: relative;
        margin-bottom: 80px;
        padding: 0 70px;
        width: 50%;
        box-sizing: border-box;
        &:nth-child(2) {
            margin-top: 200px;
        }
        .title {
            position: relative;
            height: 70px;
            line-height: 70px;
            font-weight: 600;
            text-align: center;
            font-size: 30px;
            color: #fff;
            background-color: $blueColor;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
                border-style: solid;
            }
        }
        &.leftCornor {
            .title:after {
                left: 100%;
                border-width: 35px 0 35px 35px;
                border-color: transparent transparent transparent $blueColor;
            }
        }
        &.rightCornor {
            .title:after {
                right: 100%;
                border-width: 35px 35px 35px 0;
                border-color: transparent $blueColor transparent transparent;
            }
        }
        .content {
            padding: 60px 60px 40px;
            border-right: 1px solid #d8d8d8;
            border-left: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            .img {
                img {
                    max-width: 100%;
                }
            }
            .p {
                padding: 26px 10px 0;
                font-size: 18px;
                line-height: 50px;
                color: #333;
                li {
                    position: relative;
                    padding-left: 20px;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 18px;
                        left: 0;
                        width: 8px;
                        height: 8px;
                        border: 2px solid #989898;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1660px) {
        margin: 45px -60px 0;
        .history-item {
            margin-bottom: 50px;
            padding: 0 60px;
            &:nth-child(2) {
                margin-top: 150px;
            }
            .title {
                height: 50px;
                line-height: 50px;
                font-size: 24px;
            }
            &.leftCornor {
                .title:after {
                    border-width: 25px 0 25px 25px;
                }
            }
            &.rightCornor {
                .title:after {
                    border-width: 25px 25px 25px 0;
                }
            }
            .content {
                padding: 40px 40px 30px;
                .p {
                    padding-top: 22px;
                    font-size: 16px;
                    line-height: 42px;
                    li {
                        &:before {
                            top: 15px;
                            width: 6px;
                            height: 6px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        margin: 40px -50px 0;
        .history-item {
            margin-bottom: 40px;
            padding: 0 50px;
            &:nth-child(2) {
                margin-top: 131px;
            }
            .title {
                height: 40px;
                line-height: 40px;
                font-size: 18px;
            }
            &.leftCornor {
                .title:after {
                    border-width: 20px 0 20px 20px;
                }
            }
            &.rightCornor {
                .title:after {
                    border-width: 20px 20px 20px 0;
                }
            }
            .content {
                padding: 30px 30px 20px;
                .p {
                    padding-top: 16px;
                    font-size: 15px;
                    line-height: 35px;
                    li {
                        &:before {
                            top: 13px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin: 32px -40px 0;
        .history-item {
            margin-bottom: 30px;
            padding: 0 40px;
            &:nth-child(2) {
                margin-top: 111px;
            }
            .title {
                height: 36px;
                line-height: 36px;
                font-size: 16px;
            }
            &.leftCornor {
                .title:after {
                    border-width: 18px 0 18px 18px;
                }
            }
            &.rightCornor {
                .title:after {
                    border-width: 18px 18px 18px 0;
                }
            }
            .content {
                padding: 20px 20px 12px;
                .p {
                    padding: 12px 8px 0;
                    font-size: 14px;
                    line-height: 30px;
                    li {
                        padding-left: 16px;
                        &:before {
                            top: 11px;
                            width: 5px;
                            height: 5px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        margin: 120px 0 0;
        .history-item {
            margin: px2rem(40px) 0 0;
            padding: 0 0 0 60px;
            width: 100%;
            &:nth-child(2) {
                margin-top: px2rem(40px);
            }
            .title:after {
                right: 100%;
                border-width: 18px 18px 18px 0;
                border-color: transparent $blueColor transparent transparent;
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin-top: 80px;
        .history-item {
            padding-left: 45px;
            .title {
                height: 32px;
                line-height: 32px;
                font-size: 16px;
            }
            .title:after {
                border-width: 16px 16px 16px 0;
            }
            .content {
                padding: px2rem(30px);
                .p {
                    padding: 10px 5px 0;
                    font-size: 13px;
                    line-height: 26px;
                    li {
                        padding-left: 13px;
                        &:before {
                            top: 8px;
                            width: 4px;
                            height: 4px;
                        }
                    }
                }
            }
        }
    }
}

.about-culture {
    display: flex;
    background-color: #fff;
    box-shadow: 0 40px 100px -50px rgba(0, 0, 0, .8);
    .culture-bd {
        flex: 1;
        position: relative;
        overflow: hidden;
        li {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            img {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }
    .culture-hd {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        li {
            padding: 50px 9% 35px;
            width: 50%;
            text-align: center;
            color: #333;
            box-sizing: border-box;
            cursor: pointer;
            &:first-child,
            &:nth-child(2) {
                border-bottom: 1px solid #d2d2d2;
            }
            &:nth-child(odd) {
                border-right: 1px solid #d2d2d2;
            }
            .icon {
                height: 88px;
                line-height: 88px;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 100px;
                    max-height: 100%;
                }
                .img-hover {
                    display: none;
                }
            }
            .title {
                margin: 35px 0 10px;
                font-size: 28px;
                transition: all .2s;
            }
            p {
                font-size: 16px;
                line-height: 30px;
            }
            &.on {
                .icon {
                    .img-current {
                        display: none;
                    }
                    .img-hover {
                        display: inline-block;
                    }
                }
                .title {
                    color: $blueColor;
                }
            }
        }
    }
    @media screen and (max-width: 1660px) {
        .culture-hd {
            li {
                padding: 40px 6% 25px;
                .icon {
                    height: 78px;
                    line-height: 78px;
                    img {
                        max-width: 88px;
                    }
                }
                .title {
                    margin-top: 30px;
                    font-size: 24px;
                }
                p {
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .culture-bd {
            li {
                img {
                    min-width: auto;
                    height: 100%;
                }
            }
        }
        .culture-hd {
            li {
                padding: 40px 6% 25px;
                .icon {
                    height: 60px;
                    line-height: 60px;
                    img {
                        max-width: 70px;
                    }
                }
                .title {
                    margin: 25px 0 8px;
                    font-size: 22px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        display: block;
        box-shadow: 0 px2rem(30px) px2rem(60px) px2rem(-40px) rgba(0, 0, 0, .8);
        .culture-bd {
            li {
                position: relative;
                height: 0;
                padding-bottom: (638/861) * 100%;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
            }
        }
        .culture-hd {
            li {
                padding: 40px 12% 25px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .culture-hd {
            li {
                padding: px2rem(40px) px2rem(30px) px2rem(30px);
                .icon {
                    height: 40px;
                    line-height: 40px;
                    img {
                        max-width: 50px;
                    }
                }
                .title {
                    margin: 14px 0 8px;
                    font-size: 16px;
                }
                p {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
    }
}

.search-page {
    padding: 80px 0 100px; 
    background-color: #f5f5f5;
    &-box {
        padding: 60px;
        background: #fff;
        box-shadow: 0 40px 100px -50px rgba(0, 0, 0, .8);
    }
    .search-from {
        form {
            display: flex;
            margin: 0 auto;
            max-width: 600px;
        }
        input,
        button {
            display: block;
            outline: none;
        }
        .input {
            flex: 1;
            input {
                display: block;
                padding: 0 px2rem(20px);
                width: 100%;
                height: 40px;
                font-size: 16px;
                color: #666;
                text-align: center;
                background: none;
                border: 1px solid #ccc;
                box-sizing: border-box;
            }
        }
        button {
            width: 120px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #fff;
            background: $blueColor;
            border: none;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                opacity: .8;
            }
        }
    }
    .search-list {
        margin: 50px 0 30px;
        li {
            &:first-child a {
                border-top: 1px dashed #ddd;
            }
            a {
                display: flex;
                padding: px2rem(30px);
                border-bottom: 1px dashed #ddd;
                &:hover {
                    .h3 {
                        color: $blueColor;
                        &:before {
                            background: $blueColor;
                        }
                    }
                    .time {
                        background: $blueColor;
                    }
                }
            }
            .h3 {
                flex: 1;
                height: 70px;
                font-size: 22px;
                line-height: 70px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #333;
                transition: all .3s;
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin: -2px 10px 0 0;
                    width: 7px;
                    height: 7px;
                    background: #999;
                    border-radius: 50%;
                    transition: all .3s;
                }
            }
            .time {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: px2rem(20px);
                text-align: center;
                width: 70px;
                height: 70px;
                color: #fff;
                font-family: 'Cera-GR-Bold'; 
                background-color: #6a6a6a;
                overflow: hidden;
                transition: all .3s;
                p {
                    font-size: 14px;
                }
                span {
                    margin-top: -10px;
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 1660px) {
        .search-list {
            li {
                .h3 {
                    font-size: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        padding: px2rem(60px) 0 px2rem(70px);
        &-box {
            padding: 50px;
        }
        .search-list {
            margin: 40px 0 20px;
            li {
                .h3 {
                    height: 60px;
                    font-size: 18px;
                    line-height: 60px;
                    &:before {
                        width: 6px;
                        height: 6px;
                    }
                }
                .time {
                    width: 60px;
                    height: 60px;
                    p {
                        font-size: 12px;
                    }
                    span {
                        margin-top: -7px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-box {
            padding: 50px 0;
            box-shadow: 0 px2rem(30px) px2rem(60px) px2rem(-40px) rgba(0, 0, 0, .8);
        }
    }
    @media screen and (max-width: 750px) {
        &-box {
            padding: px2rem(60px) 0;
        }
        .search-from {
            margin: 0 px2rem(30px);
            form {
                width: 100%;
                max-width: 100%;
            }
            .input {
                input {
                    height: 32px;
                    font-size: 14px;
                }
            }
            button {
                width: 80px;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
            }
        }
        .search-list {
            margin: px2rem(50px) 0 px2rem(30px);
            li {
                .h3 {
                    height: 50px;
                    font-size: 15px;
                    line-height: 50px;
                    &:before {
                        margin-right: 5px;
                        width: 5px;
                        height: 5px;
                    }
                }
                .time {
                    width: 50px;
                    height: 50px;
                    p {
                        font-size: 10px;
                    }
                    span {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
.service-page {
    overflow: hidden;
}

.service-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 80px 0 60px;
    li {
        margin: 0 10px 20px;
        a {
            display: block;
            padding: 26px 10px 0;
            width: 160px;
            height: 160px;
            text-align: center;
            border: 1px solid #d2d2d2;
            transition: all .3s;
            box-sizing: border-box;
            cursor: pointer;
        }
        &.on a,
        a:hover {
            background-color: $blueColor;
            border-color: $blueColor;
            .icon {
                .icon-current {
                    display: none;
                }
                .icon-hover {
                    display: inline-block;
                }
            }
            p {
                color: #fff;
            }
        }
        .icon {
            margin: 0 auto 24px;
            width: 56px;
            height: 56px;
            line-height: 56px;
            img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
                max-height: 100%;
            }
            .icon-hover {
                display: none;
            }
        }
        p {
            height: 22px*1.2;
            line-height: 1.2;
            font-size: 22px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 1660px) {
        li {
            margin: 0 10px 20px;
            a {
                padding-top: 22px;
                width: 140px;
                height: 140px;
            }
            .icon {
                width: 44px;
                height: 44px;
                line-height: 44px;
            }
            p {
                height: 20px*1.2;
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        margin: 60px 0 50px;
        li {
            margin: 0 5px 10px;
            a {
                width: 124px;
                height: 124px;
            }
            .icon {
                width: 36px;
                height: 36px;
                line-height: 36px;
            }
            p {
                height: 18px*1.2;
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin: 40px 0 30px;
        li {
            a {
                padding: 18px 5px 0;
                width: 100px;
                height: 100px;
            }
            .icon {
                margin-bottom: 16px;
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
            p {
                height: 16px*1.2;
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 950px) {
        display: none;
    }    
}

.service-menu-mobile,
.service-swiper-mobile .swiper-menu {
    display: none;
    font-size: 0;
    text-align: center;
    overflow: hidden;
    li {
        display: inline-block;
        vertical-align: top;
        width: auto;
        margin: 0 px2rem(8px) px2rem(16px);
        a {
            display: block;
            padding: 18px 5px 0;
            width: 100px;
            height: 100px;
            text-align: center;
            border: 1px solid #d2d2d2;
            transition: all .3s;
            box-sizing: border-box;
        }
        &.on a {
            background-color: $blueColor;
            border-color: $blueColor;
            .icon {
                .icon-current {
                    display: none;
                }
                .icon-hover {
                    display: inline-block;
                }
            }
            p {
                color: #fff;
            }
        }
        .icon {
            margin: 0 auto 16px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
                max-height: 100%;
            }
            .icon-hover {
                display: none;
            }
        }
        p {
            line-height: 1.2;
            height: 16px*1.2;
            font-size: 16px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            a {
                padding-top: 12px;
                width: 70px;
                height: 70px;
            }
            .icon {
                margin-bottom: 10px;
                width: 26px;
                height: 24px;
                line-height: 24px;
            }
            p {
                height: 13px*1.2;
                font-size: 13px;
            }
        }
    }
}

.service-menu-mobile {
    margin: px2rem(50px) 0 px2rem(35px);
    @media screen and (max-width: 950px) {
        display: block;
    }
}

.service-swiper-mobile .swiper-menu {
    display: none;
    position: absolute;
    top: px2rem(30px);
    left: 0;
    width: 100%;
    z-index: 999;
    li {
        background-color: rgba(255, 255, 255, .8);
    }
}



.service-swiper {
    &-item {
        height: 100vh;
        overflow: hidden;
        &:last-child {
            height: calc(100vh - 200px) !important;
            .item-more {
                display: none;
            }
        }
        &:nth-child(odd) {
            .item-box {
                .text-box {
                    left: (200/1920) * 100%;
                }
            }
        }
        &:nth-child(even) {
            .item-box {
                .text-box {
                    right: (200/1920) * 100%;
                }
            }
        }
    }
    .item-more {
        padding-top: 30px;
        height: 200px;
        text-align: center;
        cursor: pointer;
        .icon {
            position: relative;
            height: 101px;
            img {
                display: inline-block;
                vertical-align: top;
                max-height: 100%;
            }
        }
        p {
            margin-top: 15px;
            line-height: 1;
            font-size: 22px;
            color: #e7e7e7;
        }
        &:hover {
            .icon {
                animation: scaleMove 1s infinite;
            }
        }
    }
    
    @keyframes scaleMove {
        0% {
            top: -14px;
        }
        50% {
            top: 0;
        }
        100% {
            top: -14px;
        }
    }
    .item-box {
        position: relative;
        height: calc(100vh - 200px);
        overflow: hidden;
        &:hover {
            .bg-img {
                transform: scale3d(1.05, 1.05, 1.05);
            }
            .text-box {
                .title {
                    color: $blueColor;
                }
            }
        }
        .bg-img {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            transition: transform 1s .8s;
        }
        .text-box {
            position: absolute;
            bottom: 0;
            padding: 44px 86px 44px 50px;
            width: 450px;
            min-height: 350px;
            color: #333;
            background-color: #fff;
            border-left: 6px solid $blueColor;
            box-sizing: border-box;
            z-index: 9;
            &:after {
                content: '';
                position: absolute;
                right: 45px;
                top: 50px;
                width: 14px;
                height: 14px;
                border: 6px solid $blueColor;
                border-radius: 50%;
            }
            .title {
                margin-bottom: 32px;
                font-size: 30px;
                transition: all .3s;
            }
            .p {
                line-height: 30px;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        &-item {
            &:last-child {
                height: calc(100vh - 150px) !important;
            }
        }
        .item-more {
            padding-top: 24px;
            height: 150px;
            .icon {
                height: 80px;
            }
            p {
                font-size: 18px;
            }
        }
        
        .item-box {
            height: calc(100vh - 150px);
        }
    }
    @media screen and (max-width: 1440px) {
        &-item {
            &:last-child {
                height: calc(100vh - 120px) !important;
            }
            &:nth-child(odd) {
                .item-box {
                    .text-box {
                        left: 80px;
                    }
                }
            }
            &:nth-child(even) {
                .item-box {
                    .text-box {
                        right: 80px;
                    }
                }
            }
        }
        .item-more {
            padding-top: 20px;
            height: 120px;
            .icon {
                height: 60px;
            }
            p {
                margin-top: 10px;
                font-size: 16px;
            }
        }
        .item-box {
            height: calc(100vh - 120px);
            .text-box {
                padding: 40px 76px 40px 40px;
                width: 400px;
                min-height: 300px;
                border-left-width: 4px;
                &:after {
                    right: 35px;
                    top: 45px;
                    border-width: 4px;
                }
                .title {
                    margin-bottom: 20px;
                    font-size: 26px;
                }
                .p {
                    line-height: 28px;
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-item {
            &:last-child {
                height: calc(100vh - 100px) !important;
            }
            &:nth-child(odd) {
                .item-box {
                    .text-box {
                        left: 60px;
                    }
                }
            }
            &:nth-child(even) {
                .item-box {
                    .text-box {
                        right: 60px;
                    }
                }
            }
        }
        .item-more {
            padding-top: 15px;
            height: 100px;
            .icon {
                height: 46px;
            }
            p {
                margin-top: 10px;
                font-size: 14px;
            }
        }
        .item-box {
            height: calc(100vh - 100px);
            .text-box {
                padding: 34px 60px 30px 30px;
                width: 360px;
                min-height: 280px;
                &:after {
                    right: 28px;
                    top: 36px;
                    width: 12px;
                    height: 12px;
                }
                .title {
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        display: none;
    }
}

.service-swiper-mobile {
    display: none;
    position: relative;
    height: 100vh;
    overflow: hidden;
    .service-menu-mobile {
        position: absolute;
    }
    .service-swiper-box {
        height: 100%;
        &.show-menu {
            .text-box {
                top: 60%;
            }
        }
    }
    .swiper-slide {
        position: relative;
        height: 100vh;
        overflow: hidden;
        .bg-img {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            transition: all 1s;
        }
        .text-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 450px;
            padding: px2rem(60px) px2rem(80px) px2rem(70px) px2rem(40px);
            color: #333;
            border-left: 3px solid $blueColor;
            background-color: rgba(255, 255, 255, .8);
            transition: all .6s;
            box-sizing: border-box;
            z-index: 9;
            &:after {
                content: '';
                position: absolute;
                right: px2rem(30px);
                top: px2rem(50px);
                width: 30px;
                height: 30px;
                border: 3px solid $blueColor;
                opacity: .8;
                border-radius: 50%;
                box-sizing: border-box;
            }
            .title {
                margin-bottom: 28px;
                font-size: 30px;
            }
            .p {
                line-height: 30px;
            }
        }
    }
    @media screen and (max-width: 950px) {
        display: block;
    }    
    @media screen and (max-width: 750px) {
        .swiper-slide {
            .text-box {
                width: 86%;
                &:after {
                    right: px2rem(35px);
                    top: px2rem(60px);
                    width: 20px;
                    height: 20px;
                }
                .title {
                    margin-bottom: 16px;
                    font-size: 24px;
                }
                .p {
                    line-height: 28px;
                    font-size: 14px;
                }
            }
        }
    }
}
.partner-page {
    padding: 75px 85px 20px;
    background-color: #fff;
    box-shadow: 0 40px 100px -50px rgba(0, 0, 0, .8);
    &-title {
        margin-bottom: 30px;
        text-align: center;
        font-size: 50px;
        color: #333;
    }
    &-content {
        margin-bottom: 35px;
        color: #333;
        @include articleReset(20px, 40px);
        p {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 1660px) {
        padding: 60px 60px 20px;
        &-title {
            margin-bottom: 25px;
            font-size: 40px;
        }
        &-content {
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 36px;
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 40px 40px 20px;
        &-title {
            margin-bottom: 20px;
            font-size: 30px;
        }
        &-content {
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 32px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 40px px2rem(30px) 20px;
        box-shadow: 0 px2rem(30px) px2rem(60px) px2rem(-40px) rgba(0, 0, 0, .8);
        &-title {
            margin-bottom: 15px;
            font-size: 26px;
        }
        &-content {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 28px;
            p {
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(50px);
        padding-bottom: px2rem(30px);
        &-title {
            margin-bottom: 10px;
            font-size: 22px;
        }
        &-content {
            margin-bottom: 15px;
        }
    }
}

.partner-box {
    border-top: 3px solid $blueColor;
    .box-title {
        margin: 32px 0 26px;
        font-size: 35px;
        color: #333;
        span {
            margin-left: 12px;
            font-size: 24px;
        }
        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin: -4px 14px 0 6px;
            width: 16px;
            height: 16px;
            background-color: $blueColor;
        }
    }
    & + .partner-box {
        margin-top: 36px;
    }
    @media screen and (max-width: 1660px) {
        .box-title {
            margin: 32px 0 26px;
            font-size: 30px;
            span {
                font-size: 22px;
            }
        }
        & + .partner-box {
            margin-top: 30px;
        }
    }
    @media screen and (max-width: 1440px) {
        .box-title {
            margin: 25px 0 20px;
            font-size: 24px;
            span {
                margin-left: 8px;
                font-size: 18px;
            }
            &:before {
                margin: -5px 10px 0 6px;
                width: 12px;
                height: 12px;
            }
        }
        & + .partner-box {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 1200px) {
        .box-title {
            margin: 20px 0 15px;
            font-size: 22px;
            span {
                margin-left: 8px;
                font-size: 16px;
            }
            &:before {
                margin-right: 8px;
                width: 10px;
                height: 10px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .box-title {
            margin: 15px 0;
            font-size: 20px;
            span {
                margin-left: 5px;
                font-size: 14px;
            }
            &:before {
                margin-left: 0;
            }
        }
        & + .partner-box {
            margin-top: 15px;
        }
    }
}

.partner-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    li {
        margin-bottom: 48px;
        padding: 0 6px;
        width: (100%/6);
        text-align: center;
        box-sizing: border-box;
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (106/248) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid #d2d2d2;
                box-sizing: border-box;
                z-index: 99;
            }
        }
        p {
            margin-top: 13px;
            font-size: 24px;
        }
    }
    @media screen and (max-width: 1660px) {
        li {
            margin-bottom: 36px;
            p {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        li {
            margin-bottom: 25px;
            width: 20%;
            p {
                margin-top: 10px;
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            margin-bottom: 20px;
            p {
                margin-top: 10px;
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 950px) {
        li {
            width: 25%;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 -3px;
        li {
            margin-bottom: 16px;
            padding: 0 3px;
            width: 50%;
            p {
                margin-top: 8px;
                font-size: 14px;
            }
        }
    }
}


.common-trade {
    li {
        width: 25%;
        box-sizing: border-box;
        a {
            display: block;
        }
        .a-box,
        a {
            background-color: #fff;
            box-shadow: 0 20px 50px -20px rgba(0, 0, 0, .4);
            cursor: pointer;
            transition: all .3s;
            &:hover {
                transform: translateY(px2rem(-8px));
                .img {
                    &:before {
                        top: 0;
                    }
                    &:after {
                        top: 0;
                    }
                }
                h3,
                .more,
                .more i {
                    color: $blueColor;
                }
            }
        }
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (320/394) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 131, 214, .8);
                z-index: 6;
                transition: all .4s;
            }
            &:after {
                content: '\e659';
                position: absolute;
                top: -100%;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding-top: 8px;
                font-family: 'iconfont';
                font-size: 60px;
                color: #fff;
                box-sizing: border-box;
                z-index: 8;
                transition: all .4s;
            }
        }
        .info {
            padding: 28px 32px 50px;
        }
        h3 {
            height: 20px*1.2;
            line-height: 1.2;
            font-size: 20px;
            color: #333;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            transition: all .3s;
        }
        .p {
            margin: 18px 0 35px;
            height: 150px;
            font-size: 16px;
            line-height: 30px;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            word-break: break-all;
        }
        .more {
            font-family: 'Cera-GR-Bold';
            font-size: 18px;
            color: #454545;
            transition: all .3s;
            i {
                margin-right: 6px;
                color: #6a6a6a;
                transition: all .3s;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        li {
            .img {
                &:after {
                    font-size: 46px;
                }
            }
            .info {
                padding: 25px 25px 35px;
            }
            .p {
                margin: 15px 0 20px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        li {
            .img {
                &:after {
                    font-size: 36px;
                }
            }
            .info {
                padding: 20px 20px 30px;
            }
            h3 {
                height: 18px*1.2;
                font-size: 18px;
            }
            .p {
                margin: 10px 0 15px;
                height: 28px*5;
                line-height: 28px;
                font-size: 14px;
            }
            .more {
                font-size: 16px;
                i {
                    margin-right: 6px;
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            width: 50%;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            .a-box,
            a {
                box-shadow: 0 px2rem(10px) px2rem(30px) px2rem(-12px) rgba(0, 0, 0, .3);
                .img {
                    &:after {
                        font-size: 30px;
                    }
                }
            }
            .info {
                padding: px2rem(35px) px2rem(30px) px2rem(50px);
            }
            h3 {
                height: 16px*1.2;
                font-size: 16px;
            }
            .p {
                margin: 8px 0 13px;
                max-height: 26px*5;
                height: auto;
                line-height: 26px;
                font-size: 13px;
            }
            .more {
                font-size: 14px;
                i {
                    margin-right: 4px;
                    font-size: 12px;
                }
            }
        }
    }
}
.trade-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
    li {
        margin-bottom: 50px;
        padding: 0 25px;
    }
    @media screen and (max-width: 1660px) {
        margin: 0 -15px 20px;
        li {
            margin-bottom: 30px;
            padding: 0 15px;
        }
    }
    @media screen and (max-width: 1440px) {
        margin: 0 px2rem(-10px) px2rem(20px);
        li {
            margin-bottom: px2rem(20px);
            padding: 0 px2rem(10px);
        }
    }
}


.pupop-mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 2222;
}
.trade-pupop {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 55px 70px;
    width: 900px;
    max-width: 90%;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 2228;
    &.nocenter {
        top: px2rem(30px);
        transform: translate(-50%, 0);
    }
    @media screen and (max-width: 1660px) {
        padding: 50px 40px 60px;
        width: 700px;
    }
    @media screen and (max-width: 1440px) {
        padding: 40px 30px 50px;
        width: 600px;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(40px) px2rem(40px) px2rem(50px);
    }
}

.pupop-close {
    position: absolute;
    top: 0;
    left: 100%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    background-color: $blueColor;
    cursor: pointer;
    z-index: 2230;
    transition: all .3s;
    &:hover {
        opacity: .8;
    }     
    @media screen and (max-width: 750px) {
        left: auto;
        right: 0;
        padding-left: 4px;
        width: 36px;
        height: 40px;
        line-height: 34px;
        font-size: 14px;
        border-bottom-left-radius: 40px;
        opacity: .8;
        &:hover {
            opacity: 1;
        }
    }  
}

.pupop-box {
    .img {
        position: relative;
        height: 0;
        padding-bottom: (320/394) * 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .h3 {
        margin: 30px 0 25px;
        font-size: 40px;
        color: #000;
    }
    .p {
        font-size: 16px;
        line-height: 36px;
        color: #666;
    }
    @media screen and (max-width: 1660px) {
        .h3 {
            margin-bottom: 20px;
            font-size: 32px;
        }
        .p {
            font-size: 15px;
            line-height: 32px;
        }
    }
    @media screen and (max-width: 1440px) {
        .h3 {
            margin-bottom: 20px;
            font-size: 26px;
        }
        .p {
            font-size: 14px;
            line-height: 30px;
        }
    }
    @media screen and (max-width: 750px) {
        .h3 {
            margin: 15px 0 10px;
            font-size: 20px;
        }
        .p {
            font-size: 13px;
            line-height: 28px;
        }
    }
}
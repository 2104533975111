.news-list {
    padding-bottom: 25px;
    @media screen and (max-width: 1440px) {
        padding-bottom: 0;
    }
    li {
        margin-bottom: px2rem(30px);
        a {
            position: relative;
            display: block;
            padding-right: 160px;
            transition: all .3s;
            &:hover {
                transform: translateY(px2rem(-8px));
                .time {
                    background-color: $blueColor;
                }
                .item-main {
                    h3,
                    .more,
                    .more i {
                        color: $blueColor;
                    }
                }
            }
        }
        .time {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 160px;
            height: 160px;
            color: #fff;
            font-family: 'Cera-GR-Bold'; 
            background-color: #6a6a6a;
            transition: all .3s;
            .day {
                margin-top: -20px;
                font-size: 60px;
            }
            .year {
                margin-top: -12px;
                font-size: 24px;
            }
        }
        .item-main {
            display: flex;
            padding: 20px 160px 20px 20px;
            background-color: #fff;
            box-shadow: 0 0 20px rgba(0, 0, 0, .2);
            .img {
                margin-right: 72px;
                width: 428px;
                & ~ .info {
                    flex: 1;
                    width: 0;
                    padding-top: 32px;
                }
                div {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: (243/428) * 100%;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .info {
                padding: 15px 0;
                width: 100%;
            }
            h3 {
                height: 24px*1.2;
                line-height: 1.2;
                font-size: 24px;
                color: #333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all .3s;
            }
            .p {
                margin: 18px 0 22px;
                max-height: 60px;
                font-size: 16px;
                line-height: 30px;
                color: #666;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
            .more {
                font-family: 'Cera-GR-Bold';
                font-size: 18px;
                color: #454545;
                transition: all .3s;
                i {
                    margin-right: 6px;
                    color: #6a6a6a;
                    transition: all .3s;
                }
            }
        }
        @media screen and (max-width: 1660px) {
            a {
                padding-right: 140px;
            }
            .time {
                margin-top: -70px;
                width: 140px;
                height: 140px;
                .day {
                    margin-top: -18px;
                    font-size: 50px;
                }
                .year {
                    margin-top: -8px;
                    font-size: 22px;
                }
            }
            .item-main {
                padding-right: 80px;
                .img {
                    margin-right: 40px;
                    width: 380px;
                }
                h3 {
                    height: 22px*1.2;
                    font-size: 22px;
                }
                .p {
                    font-size: 15px;
                }
                .more {
                    font-size: 16px;
                    i {
                        font-size: 14px;
                    }
                }
            }
        }
        @media screen and (max-width: 1440px) {
            a {
                padding-right: 120px;
            }
            .time {
                margin-top: -60px;
                width: 120px;
                height: 120px;
                .day {
                    font-size: 42px;
                }
                .year {
                    margin-top: -6px;
                    font-size: 20px;
                }
            }
            .item-main {
                padding-right: 40px;
                .img {
                    margin-right: 30px;
                    width: 320px;
                    & ~ .info {
                        padding-top: 20px;
                    }
                }
                .info {
                    padding: 10px 0;
                }
                h3 {
                    height: 20px*1.2;
                    font-size: 20px;
                }
                .p {
                    margin: 15px 0 18px;
                    max-height: 56px;
                    font-size: 14px;
                    line-height: 28px;
                }
                .more {
                    font-size: 14px;
                    i {
                        font-size: 12px;
                    }
                }
            }
        }
        @media screen and (max-width: 950px) {
            a {
                padding-right: 0;
            }
            .time {
                display: none;
            }
            .item-main {
                padding-right: 20px;
                .img {
                    margin-right: 20px;
                    width: 300px;
                }
            }
        }
        @media screen and (max-width: 750px) {
            .time {
                display: none;
            }
            .item-main {
                display: block;
                padding: px2rem(30px) px2rem(30px) px2rem(40px);
                .img {
                    margin-right: 0;
                    width: 100%;
                    & ~ .info {
                        padding-top: 16px;
                        width: 100%;
                    }
                }
                .info {
                    padding: px2rem(10px) 0 0;
                }
                h3 {
                    height: 16px*1.2;
                    font-size: 16px;
                }
                .p {
                    margin: 10px 0 12px;
                    max-height: 52px;
                    font-size: 13px;
                    line-height: 26px;
                }
                .more {
                    font-size: 13px;
                    i {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}

.news-article {
    padding: 56px 86px 80px;
    background-color: #fff;
    box-shadow: 0 40px 100px -50px rgba(0, 0, 0, .8);
    &-title {
        margin-bottom: 30px;
        padding: 0 5%;
        text-align: center;
        font-size: 26px;
        color: #333;
    }
    &-param {
        display: flex;
        padding: 18px 50px;
        height: 24px;
        line-height: 24px;
        color: #333;
        background-color: #f5f5f5;
        .left {
            flex: 1;
            span {
                display: inline-block;
                vertical-align: middle;
                &:not(:first-child) {
                    margin-left: 56px;
                }
            }
        }
        .right {
            display: flex;
            a {
                float: none;
                margin: 0 0 0 7px;
                &:nth-of-type(1) {
                    margin-left: 0;
                }
            }
        }
    }
    &-content {
        padding: 50px 50px 30px;
        color: #666;
        @include articleReset(16px, 30px);
        overflow: hidden;
        p {
            margin-bottom: 24px;
        }
        iframe {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 1660px) {
        padding: 50px 50px 60px;
        &-param {
            padding: 18px 40px;
        }
        &-content {
            padding: 40px 40px 15px;
        }
    }
    @media screen and (max-width: 1440px) {
        &-title {
            margin-bottom: 25px;
            font-size: 22px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: px2rem(50px) px2rem(30px) px2rem(70px);
        box-shadow: 0 px2rem(30px) px2rem(60px) px2rem(-40px) rgba(0, 0, 0, .8);
        &-title {
            font-size: 20px;
        }
        &-param {
            padding: 18px 30px;
            height: 18px;
            line-height: 18px;
            font-size: 14px;
            .left {
                span {
                    &:not(:first-child) {
                        margin-left: 30px;
                    }
                }
            }
            .right {
                a {
                    margin-left: 4px;
                    padding-left: 18px;
                    height: 18px;
                    line-height: 18px;
                    background-size: 18px auto;
                }
                .bds_qzone {
                    background-position: 0 -39px;
                }
                .bds_tsina {
                    background-position: 0 -78px;
                }
                .bds_tqq {
                    background-position: 0 -195px;
                }
                .bds_renren {
                    background-position: 0 -156px;
                }
                .bds_weixin {
                    background-position: 0 -1209px;
                }
            }
        }
        &-content {
            padding: 30px 30px 20px;
            font-size: 14px;
            line-height: 28px;
            p {
                margin-bottom: 15px;
            }
        }
    } 
    @media screen and (max-width: 750px) {
        &-title {
            margin-bottom: 16px;
            font-size: 16px;
        }
        &-param {
            display: block;
            padding: px2rem(30px);
            height: auto;
            font-size: 12px;
            text-align: center;
            .left {
                span {
                    margin: 0 px2rem(15px);
                    &:not(:first-child) {
                        margin-left: px2rem(15px);
                    }
                }
            }
            .right {
                margin-top: 6px;
                justify-content: center;
            }
        }
        &-content {
            padding: px2rem(30px) 0 px2rem(20px);
            p {
                margin-bottom: 10px;
            }
        }
    } 
}

.news-paged {
    margin: 0 50px;
    display: flex;
    height: 60px;
    line-height: 60px;
    a {
        flex: 1;
        width: 0;
        padding: 0 30px;
        color: #333;
        background-color: #f5f5f5;
        overflow: hidden;
        transition: all .3s;
        &:not(:first-child) {
            margin-left: 18px;
        }
        i {
            display: inline-block;
            vertical-align: middle;
            font-size: 17px;
            line-height: 60px;
            color: #999;
        }
        &:hover {
            color: #fff;
            background-color: $blueColor;
            i {
                color: #fff;
                transition: all .3s;
            }
        }
    }
    .back-list {
        text-align: center;
    }
    .paged-prev,
    .paged-next {
        display: flex;
        span {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .paged-prev,
    .back-list {
        i {
            margin-right: 6px;
        }
    }
    .paged-next {
        i {
            margin-left: 6px;
        }
    }
    @media screen and (max-width: 1660px) {
        margin: 0 40px;
    }
    @media screen and (max-width: 1200px) {
        margin: 0 30px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        a {
            padding: 0 px2rem(15px);
            &:not(:first-child) {
                margin-left: px2rem(10px);
            }
            i {
                font-size: 14px;
                line-height: 50px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        a {
            i {
                font-size: 12px;
                line-height: 40px;
            }
        }
    }
}
@import '//at.alicdn.com/t/font_1118441_mabttrplaao.css';

$blueColor: #0082d6;

.pd100 {
    padding: 0 100px;
    @media screen and (max-width: 1800px) {
        padding: 0 80px;
    }
    @media screen and (max-width: 1660px) {
        padding: 0 60px;
    }
    @media screen and (max-width: 1200px) {
        padding: 0 px2rem(30px);
    }
}

.wp1440 {
    max-width: 1440px;
    margin: 0 auto;
    @media screen and (max-width: 1800px) {
        max-width: 1300px;
    }
    @media screen and (max-width: 1660px) {
        max-width: 1200px;
    }
    @media screen and (max-width: 1440px) {
        max-width: 1000px;
    }
    @media screen and (max-width: 1200px) {
        padding: 0 px2rem(30px);
    }
}

.inside-banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: (480/1920) * 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &-mobile {
        width: 100%;
        height: 0;
        padding-bottom: (360/750) * 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }
    @media screen and (max-width: 750px) {
        display: none;
        &-mobile {
            display: block;
        }
    }
}

.bread-con {
    position: absolute;
    bottom: 18px;
    right: 0;
    padding: 0 100px;
    width: 100%;
    text-align: right;
    font-size: 18px;
    color: #fff;
    box-sizing: border-box;
    z-index: 99;
    a {
        color: #fff;
        transition: all .3s;
        i {
            display: inline-block;
            margin-right: 6px;
            font-size: 22px;
        }
        &:hover {
            color: $blueColor;
        }
    }
    @media screen and (max-width: 1800px) {
        padding: 0 80px;
    }
    @media screen and (max-width: 1660px) {
        padding: 0 60px;
        font-size: 16px;
        a {
            i {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        font-size: 14px;
        a {
            i {
                margin-right: 4px;
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        display: none;
    }
}


.inside-menu {
    .pd100 {
        display: flex;
        height: 100px;
        line-height: 100px;
    }
    &-title {
        width: 308px;
        text-align: center;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        background: $blueColor url(../images/menu_bg.png) right center no-repeat;
        background-size: auto 100%;
    }
    &-box {
        flex: 1;
        overflow: hidden;
        &.no-init {
            .swiper-wrapper {
                width: 100%;
                display: block;
            }
        }
        .swiper-wrapper {
            font-size: 0;
        }
        .swiper-slide {
            display: inline-block;
            vertical-align: top;
            margin-left: 40px;
            width: auto;
            padding: 0 25px;
            font-size: 20px;
            color: #333;
            box-sizing: border-box;
            transition: all .3s;
            &.on {
                border-bottom: 5px solid $blueColor;
            }
            &.on,
            &:hover {
                color: $blueColor;
                font-weight: 600;
            }
        }
    }
    @media screen and (max-width: 1660px) {
        .pd100 {
            height: 85px;
            line-height: 85px;
        }
        &-title {
            width: 260px;
            font-size: 32px;
        }
        &-box {
            .swiper-slide {
                margin-left: 30px;
                padding: 0 20px;
                font-size: 18px;
                &.on {
                    border-bottom-width: 4px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .pd100 {
            height: 70px;
            line-height: 70px;
        }
        &-title {
            width: 200px;
            font-size: 24px;
        }
        &-box {
            .swiper-slide {
                padding: 0 px2rem(15px);
                font-size: 16px;
                &.on {
                    border-bottom-width: 3px;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        .pd100 {
            height: 45px;
            line-height: 45px;
        }
        &-title {
            display: none;
        }
        &-box {
            &.no-init {
                .swiper-wrapper {
                    text-align: center;
                }
            }
            .swiper-slide {
                margin: 0 px2rem(20px);
                font-size: 15px;
                &.on {
                    border-bottom-width: 2px;
                }
            }
        }
    }
}


.paged {
    padding-top: px2rem(30px);
    text-align: center;
    font-size: 0;
}
.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    margin: 0 4px 8px;
    padding: 0 14px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #afafaf;
    background: #fff;
    color: #555;
    border-radius: 1px;
    transition: all .3s;
    @media screen and (max-width: 750px) {
        margin: 0 2px 4px;
        padding: 0 11px;
        height: 28px;
        line-height: 28px;
        font-size: 13px;
    }
}

.paged .prev,
.paged .next {
    padding: 0 26px;
    @media screen and (max-width: 750px) {
        padding: 0 16px;
    }
}

.paged .current,
.paged a:hover {
    border: 1px solid $blueColor;
    background: $blueColor;
    color: #fff; 
} 

@font-face {
    font-family: 'Cera-GR-Bold'; 
    src: url('../font/Cera-GR-Bold.eot');
    src: url('../font/Cera-GR-Bold.woff') format('woff'), url('../font/Cera-GR-Bold.ttf') format('truetype'), url('../font/Cera-GR-Bold.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'IMPACT'; 
    src: url('../font/IMPACT.eot');
    src: url('../font/IMPACT.woff') format('woff'), url('../font/IMPACT.ttf') format('truetype'), url('../font/IMPACT.svg') format('svg');
    font-style: normal;
}
.contact-box {
    display: flex;
    padding: px2rem(30px);
    background-color: #fff;
    box-shadow: 0 40px 100px -50px rgba(0, 0, 0, .8);
    @media screen and (max-width: 1200px) {
        display: block;
        box-shadow: 0 px2rem(30px) px2rem(60px) px2rem(-40px) rgba(0, 0, 0, .8);
    }
}

.contact-info {
    margin-right: 20px;
    padding: 65px 45px 50px 40px;
    width: 495px;
    background-color: #f5f5f5;
    box-sizing: border-box;
    .title {
        margin-bottom: 32px;
        padding-bottom: 20px;
        font-size: 36px;
        color: #333;
        border-bottom: 1px solid #d5d5d5;
    }
    .p {
        font-size: 18px;
        line-height: 26px;
        color: #333;
        p {
            margin-bottom: 16px;
        }
    }
    .img {
        margin-top: 70px;
        padding-left: 70px;
        img {
            max-width: 60%;
            height: auto;
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 40px 30px 50px;
        width: 400px;
        .title {
            margin-bottom: 26px;
            padding-bottom: 16px;
            font-size: 32px;
        }
        .p {
            font-size: 16px;
            line-height: 24px;
            p {
                margin-bottom: 12px;
            }
        }
        .img {
            margin-top: 50px;
            padding-left: 50px;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(50px) px2rem(30px) px2rem(60px);
        .title {
            margin-bottom: 16px;
            padding-bottom: 12px;
            font-size: 24px;
        }
        .p {
            font-size: 14px;
            line-height: 20px;
        }
        .img {
            margin-top: px2rem(60px);
            padding-left: 0;
            text-align: center;
            img {
                max-width: 50%;
            }
        }
    }
}

.contact-map {
    flex: 1;
    .map-box {
        width: 100%;
        height: 100%;
        min-height: 645px;
    }
    @media screen and (max-width: 1440px) {
        .map-box {
            min-height: 500px;
        }
    }
    @media screen and (max-width: 750px) {
        .map-box {
            min-height: auto;
            height: px2rem(600px);
        }
    }
}
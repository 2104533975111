.header {
    position: relative;
    background: #fff;
    @media screen and (max-width: 1200px) {
        display: none;
    }
    .pd100 {
        display: flex;
        align-items: center;
        height: 100px;
        line-height: 100px;
        @media screen and (max-width: 1440px) {
            height: 80px;
            line-height: 80px;
        }
    }
    .logo {
        width: (548/1725) * 100%;
        img {
            max-width: 100%;
        }
        @media screen and (max-width: 1440px) {
            width: 29%;
        }
    }
    .header-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
    .header-nav {
        font-size: 0;
        .nav-li {
            display: inline-block;
            vertical-align: top;
            margin-left: 35px;
            &:hover,
            &.on {
                .nav-a {
                    color: $blueColor;
                }
            }
            &:hover {
                .nav-a:after {
                    display: block;
                }
                .nav-sub {
                    display: block;
                }
            }
        }
        .nav-a {
            position: relative;
            display: block;
            font-size: 22px;
            color: #333;
            transition: all .3s;
            z-index: 2005;
            &:after {
                display: none;
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                margin-left: -10px;
                width: 0;
                height: 0;
                border-width: 0 10px 8px;
                border-style: solid;
                border-color: transparent transparent #f5f5f5;
                z-index: 22;
            }
        }
        @media screen and (max-width: 1800px) {
            .nav-li {
                margin-left: 32px;
            }
            .nav-a {
                font-size: 20px;
            }
        }
        @media screen and (max-width: 1660px) {
            .nav-li {
                margin-left: 24px;
            }
            .nav-a {
                font-size: 18px;
            }
        }
        @media screen and (max-width: 1440px) {
            .nav-li {
                margin-left: 19px;
            }
            .nav-a {
                font-size: 16px;
                &:after {
                    margin-left: -8px;
                    border-width: 0 8px 6px;
                }
            }
        }
    }
    .nav-sub {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 30px 0;
        width: 100%;
        background-color: #f5f5f5;
        opacity: 1;
        animation: showDown .5s;
        z-index: 2001;
        .wp1440 {
            display: flex;
            justify-content: flex-end;
        }
        @keyframes showDown{
            from { top: 50%; opacity: 0}
            to { top: 100%; opacity: 1 }
        }
        .normal-wp {
            align-items: center;
        }
        &-service {
            flex: 1;
            display: flex;
            flex-flow: column wrap;
            align-content: flex-end;
            padding-top: 24px;
            height: 242px;
            li {
                margin: 0 25px;
                &:nth-child(even) {
                    margin-top: 38px;
                }
            }
            a {
                display: block;
                width: 130px;
                text-align: center;
                &:hover {
                    .icon {
                        .icon-default {
                            display: none;
                        }
                        .icon-hover {
                            display: inline-block;
                        }
                    }
                    .text {
                        color: $blueColor;
                    }
                }
            }
            .icon {
                height: 56px;
                line-height: 56px;
                img {
                    display: inline-block;
                    vertical-align: top;
                    max-height: 100%;
                    max-width: 100%;
                }
                .icon-hover {
                    display: none;
                }
            }
            .text {
                margin-top: 20px;
                line-height: 1.4;
                font-size: 18px;
                color: #333;
                transition: all .3s;
            }
        }
        &-list {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            li {
                margin: 12px 50px 12px 0;
            }
            a {
                display: block;
                width: 88px;
                text-align: center;
                line-height: 1.4;
                font-size: 18px;
                color: #333;
                transition: all .3s;
                &:hover {
                    color: $blueColor;
                }
            }
        }
        &-img {
            margin-left: 12px;
            width: 426px;
            height: 286px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
        @media screen and (max-width: 1660px) {
            &-service {
                padding-top: 14px;
                height: 211px;
                li {
                    margin: 0 20px;
                    &:nth-child(even) {
                        margin-top: 26px;
                    }
                }
                a {
                    width: 120px;
                }
                .icon {
                    height: 50px;
                    line-height: 50px;
                }
                .text {
                    font-size: 16px;
                }
            }
            &-list {
                li {
                    margin-right: 40px;
                }
                a {
                    font-size: 16px;
                }
            }
            &-img {
                width: 380px;
                height: 231px;
            }
        }
        @media screen and (max-width: 1440px) {
            padding: 25px 0;
            &-service {
                padding-top: 14px;
                height: 188px;
                li {
                    margin: 0 16px;
                    &:nth-child(even) {
                        margin-top: 24px;
                    }
                }
                a {
                    width: 110px;
                }
                .icon {
                    height: 46px;
                    line-height: 46px;
                }
                .text {
                    margin-top: 15px;
                    font-size: 15px;
                }
            }
            &-list {
                li {
                    margin-right: 32px;
                }
            }
            &-img {
                width: 340px;
                height: 207px;
            }
        }
    }
    .lang-search {
        margin-left: 20px;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            font-size: 18px;
            color: #333;
            i {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;
            }
            a {
                display: block;
                color: #333;
                transition: all .3s;
                span {
                    margin-left: 2px;
                }
                &:hover {
                    color: $blueColor;
                }
            }
        }
        .search-btn {
            position: relative;
            padding-left: 15px;
            margin-left: 15px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: $blueColor;
            }
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 18px;
                background-color: #a0a0a0;
            }
        }
        @media screen and (max-width: 1800px) {
            li {
                font-size: 16px;
                i {
                    font-size: 20px;
                }
            }
            .search-btn:before {
                height: 16px;
            }
        }
        @media screen and (max-width: 1440px) {
            li {
                font-size: 14px;
                i {
                    font-size: 18px;
                }
            }
            .search-btn {
                padding-left: 12px;
                margin-left: 12px;
                &:before {
                    height: 14px;
                }
            }
        }
    }
    .right-logo {
        margin-left: 30px;
        width: 189px;
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            height: auto;
        }
        .img-hover {
            display: none;
        }
        &:hover {
            .img-current {
                display: none;
            }
            .img-hover {
                display: inline-block;
            }
        }
        @media screen and (max-width: 1800px) {
            margin-left: 26px;
            width: 170px;
        }
        @media screen and (max-width: 1440px) {
            margin-left: 18px;
            width: 130px;
        }
    }
}

.header-search {
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    padding: 30px 0;
    background-color: #f5f5f5;
    z-index: 2000;
    form {
        display: flex;
    }
    input,
    button {
        outline: none;
        border: none;
    }
    .input {
        flex: 1;
        background-color: #fff;
        input {
            display: block;
            padding: 0 px2rem(20px);
            width: 100%;
            height: 40px;
            font-size: 16px;
            color: #666;
            text-align: center;
            background: none;
            box-sizing: border-box;
        }
    }
    button {
        margin-left: 60px;
        width: 150px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        background: $blueColor;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            opacity: .8;
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 25px 0;
        top: 80px;
        .input {
            input {
                height: 35px;
                font-size: 15px;
            }
        }
        button {
            margin-left: 40px;
            width: 120px;
            height: 35px;
            line-height: 35px;
            font-size: 15px;
        }
    }
    @media screen and (max-width: 1200px) {
        top: px2rem(100px);
        padding: px2rem(40px) px2rem(30px);
        box-sizing: border-box;
        .input {
            input {
                height: px2rem(60px);
                font-size: px2rem(26px);
            }
        }
        button {
            margin-left: px2rem(20px);
            width: px2rem(160px);
            height: px2rem(60px);
            line-height: px2rem(60px);
            font-size: px2rem(26px);
        }
    }
}

.mobile-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 px2rem(30px);
    height: px2rem(100px);
    line-height: px2rem(100px);
    @media screen and (max-width: 1200px) {
        display: flex;
    }
    .logo {
        width: px2rem(588px);
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .right {
        display: flex;
        color: #333;
        li {
            display: flex;
            align-items: center;
            margin-left: px2rem(35px);
            i {
                display: inline-block;
                vertical-align: middle;
                padding-top: 3px;
                font-size: px2rem(45px);
            }
        }
    }
}

#menuClose {
    display: none;
}

.nav-mobile {
    position: fixed;
    top: px2rem(100px);
    left: 0;
    right: 0;
    bottom: px2rem(130px);
    overflow: hidden;
    transition: transform .4s ease;
    &-con {
        display: none;
        position: fixed;
        top: px2rem(100px);
        left: 0;
        right: 0;
        bottom: 0;
        background: #f0f0f0;
        border-top: 1px solid #d2d2d2;
        overflow: hidden;
        z-index: 8888;
        transition: transform .4s ease;
        &.anim {
            top: 0;
        }
    }
    &-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        padding: 0 px2rem(30px);
        width: 100%;
        height: px2rem(130px);
        line-height: px2rem(130px);
        border-top: 1px solid #cdcdcd;
        z-index: 8889;
        box-sizing: border-box;
        li {
            flex: 1;
            text-align: center;
            a {
                display: block;
            }
        }
        .menu-logo {
            img {
                display: inline-block;
                vertical-align: middle;
                max-width: 60%;
            }
        }
        .menu-lang {
            position: relative;
            color: #333;
            i {
                display: inline-block;
                vertical-align: middle;
                font-size: px2rem(40px);
            }
            span {
                margin-left: px2rem(12px);
                display: inline-block;
                vertical-align: middle;
                font-size: px2rem(30px);
            }
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: px2rem(-20px);
                width: 1px;
                height: px2rem(40px);
                background-color: #cdcdcd; 
            }
        }
    }
    &-wp {
        position: relative;
        & > li {
            border-bottom: 1px solid #cdcdcd;
        }
    }
    &-title {
        position: relative;
        margin: 0 px2rem(30px);
        > a {
            position: relative;
            display: block;
            line-height: px2rem(100px);
            font-size: px2rem(30px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
            width: 100%;
        }
    }
    &-child-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(84px);
        font-size: px2rem(30px);
        font-weight: bold;
        color: #555;
        z-index: 1;
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            position: absolute;
            top: 0;
            right: 0;
            line-height: px2rem(100px);
            font-size: px2rem(24px);
            color: #333;
            font-family: "iconfont";
            transition: transform .2s ease;
            &:before {
                content: "\e603";
            }
        }
        &.open {
            i {
                &:before {
                    content: "\e6cb";
                }
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub {
        display: none;
        background-color: #fff;
        border-top: 1px solid #cdcdcd;
        li {
            position: relative;
            overflow: hidden;
            &:not(:last-child) a {
                border-bottom: 1px solid #cdcdcd;
            } 
            a {
                position: relative;
                display: block;
                padding: 0 px2rem(30px);
                line-height: px2rem(100px);
                font-size: px2rem(30px);
                height: px2rem(100px);
                color: #999;
                overflow: hidden;
                box-sizing: border-box;
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin: -2px px2rem(10px) 0 0;
                    width: 6px;
                    height: 1px;
                    background-color: #999;
                }
            }
        }
    }
} 